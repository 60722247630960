@import '../../themes/generated/variables.base.scss';
@import '../../dx-styles.scss';

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}

.icon-distance {
  margin-right: 10px;
}

.icon-distance-2 {
  margin-right: 5px;
}

#callDraggable {
  position: fixed;
  width: 256px;
  height: 500px;
  top: 10px;
  left: 0px;
  z-index: 10000;
}

#callOverlay {
  position: fixed;
  width: 320px;
  min-width: 320px;
  height: 575px;
  min-height: 575px;
  background: rgba(0, 0, 0, 0.5);
  top: 10%;
  border-radius: 5px;
  left: 38%;
  z-index: 10000;
}

#externalCallOverlay {
  position: fixed;
  width: 140px;
  height: 170px;
  background: rgba(0, 0, 0, 0.5);
  top: 42%;
  border-radius: 5px;
  left: 44%;
  z-index: 10000;
}

#externalCallInfo {
  position: absolute;
  top: 56px;
  left: 18px;
  width: 140px;
  color: white;
}

#ongoingCall {
  margin-left: 10px;
}

#externalCallMuteButton {
  margin-left: 10px;
}

#callerHangUp {
  margin-left: 10px;
}

#hangUpIncoming {
  margin-left: 30px !important;
}

#startExternalCall > div > span {
  text-transform : none !important;
}

#startExternalCallNow {
  position: absolute;
  right: 10px;
  margin-top: 20px;
}

#endCall {
  position: absolute;
  right: 0;
  top: 5px;
  background: none !important;
  color: white !important;
  width: 60px;
  height: 40px;
  border-color: rgba(0, 0, 0, 0) !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

#endCall:hover {
  color: black !important;
}

#overdue-text-color {
  color: rgb(255, 0, 0);
}
