.takeButton {
  font-size: 12px;
  margin-right: 5px;
}

.riskButton {
  font-size: 12px;
}

#stop-button {
  position: absolute;
  color: #b72c2c;
  right: 40px;
  bottom: 120px;
}

#stop-button:hover {
  color: #da3434;
}

#gridContainer span.current-value {
  display: inline-block;
  margin-right: 5px;
}

#gridContainer span.diff {
  width: 50px;
  display: inline-block;
}

#gridContainer .inc {
  color: #2ab71b;
}

#gridContainer .dec {
  color: #f00;
}

#gridContainer .inc .arrow,
#gridContainer .dec .arrow,
#gridContainer .equ .arrow {
  display: inline-block;
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  background-size: 10px 10px;
}

#gridContainer .inc .arrow {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
}

#gridContainer .dec .arrow {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
}

#gridContainer .equ .arrow {
  background-color: rgb(196, 77, 204);
}

.orderUp {
  color: #239e23;
}

.orderDown {
  color: #da3434;
}

.payoutEqual {
  color: rgb(196, 77, 204);
}

.payoutSuccess {
  color: rgb(0, 102, 255);
}

.payoutLoss {
  color: rgb(255, 119, 0);
}

.potentialPayoutEqual {
  color: white;
  font-weight: 500;
  background-color: rgb(196, 77, 204);
  line-height: 2;
  height: 25px;
}

.potentialPayoutSuccess {
  color: white;
  font-weight: 500;
  background-color: rgba(0, 102, 255, 1);
  line-height: 2;
  height: 25px;
}

.potentialPayoutLoss {
  color: white;
  font-weight: 500;
  background-color: rgb(255, 119, 0);
  line-height: 2;
  height: 25px;
}

.timerDefault {
  color: black;
  font-weight: 500;
  background-color: white;
  line-height: 2;
  height: 25px;
}

.timerEqual {
  color: white;
  font-weight: 500;
  background-color: rgb(196, 77, 204);
  line-height: 2;
  height: 25px;
}

.timerSuccess {
  color: white;
  font-weight: 500;
  background-color: rgb(0, 102, 255);
  line-height: 2;
  height: 25px;
}

.timerLoss {
  color: white;
  font-weight: 500;
  background-color: rgb(255, 119, 0);
  line-height: 2;
  height: 25px;
}

.timerWarning {
  color: black;
  font-weight: 500;
  background-color: rgb(255, 234, 0);
  line-height: 2;
  height: 25px;
}