#header-balance {
  margin-bottom: 6px !important;
}

#tabsHistory .dx-tabs-scrollable .dx-tab {
  display: none;
}

.selectBoxChart {
  width: 70px;
  background-color: #f3f3f3 !important;
  z-index: 1000;
}

#selectBoxChartSeriesType {
  margin-left: 80px;
}

#progress-bar-users-choice {
  margin-top: 5px;
}

#progress-bar-users-choice > div > div > div > div {
  height: 7px !important;
  background-color: #239e23 !important;
  border-radius: 5px !important;
}

#progress-bar-users-choice > div > div > div > div > div {
  background-color: #da3434 !important;
  border-radius: 5px !important;
  margin-top: 8px !important;
}

#progress-bar-users-choice-zero {
  margin-top: 5px;
}

#progress-bar-users-choice-zero > div > div > div > div {
  height: 7px !important;
  background-color: #9fa59f !important;
  border-radius: 5px !important;
}

#progress-bar-users-choice-zero > div > div > div > div > div {
  background-color: #da3434 !important;
  border-radius: 5px !important;
  margin-top: 8px !important;
}

#pred-asset-select {
  width: 155px;
  margin-right: 50px;
}

#pred-asset-select-row {
  width: 185px;
  margin-right: 15px;
}

#pred-resolution-select {
  width: 75px;
  margin-right: 50px;
}

#pred-resolution-select-row {
  width: 95px;
}

#pred-amount-select {
  margin-bottom: 10px;
}

#pred-expiry-select {
  width: 110px;
  margin-bottom: 10px;
}

/* .prediction-header-items {
}
*/

#pred-asset-area {
  max-width: 320px;
  width: 300px;
}

#pred-asset-area-row {
  max-width: 320px;
  width: 300px;
  margin-bottom: 10px;
}

#tabsPeriod {
  max-width: 320px;
  width: 240px;
  margin-top: 17px;
}

#tabsPeriod > .dx-tabs-wrapper > .dx-tab {
  padding: 3px 0px !important;
}

#tabsPeriod .dx-tab {
  background-color: white;
}

#tabsPeriod > .dx-tabs-wrapper > .dx-tab.dx-state-hover {
  background-color: white;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 42%);
}

#tabsPeriod-row {
  max-width: 320px;
  width: 295px;
}

#tabsPeriod-row > .dx-tabs-wrapper > .dx-tab {
  padding: 4px 0px !important;
}

.placePrediction {
  margin-top: 18px !important;
  position: absolute;
  right: 10px;
  border-radius: 3px !important;
  /* background-color: #da3434 !important; */
}

.placeOrderValues {
  border-bottom: 1px solid #e0e0e0;
}

#orderButtonsMenu {
  margin-left: 40px;
  margin-right: 0;
}

#selectBoxExpiry {
  position: absolute;
  width: 110px;
}

#customExpiryDate {
  margin-left: 110px;
}

#payoutDiv {
  text-align: center;
  padding: 18px;
  padding-top: 58px;
  margin-left: 40px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
}

#orderPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 110px;
  line-height: 0px;
  width: 110px;
  background-color: rgb(0, 102, 255);
  border-radius: 10px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
}

#orderPrice .lastPlacedOrderIcons {
  position: relative;
  bottom: 5px;
}

#lastPlacedOrderPrice {
  align-self: flex-start;
  color: white;
  font-size: 10px;
  margin-left: 10px;
}

#placeOrderPrice {
  align-self: center;
  color: white;
  font-size: 14px;
  line-height: 70px;
}

#lastPlacedOrderTypeUp {
  align-self: flex-start;
  color: white;
  font-size: 20px;
  margin-left: 10px;
  height: 0px;
  line-height: 0px;
}

#lastPlacedOrderTypeDown {
  align-self: flex-end;
  color: white;
  font-size: 20px;
  margin-right: 10px;
  height: 0px;
  line-height: 0px;
}

.lastPlacedOrderIcons > svg > g > path {
  opacity: 1 !important;
}

#orderPrice-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  height: 50px;
  line-height: 0px;
  width: 110px;
  background-color: rgb(0, 102, 255);
  border-radius: 10px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
}

#orderPrice-row .lastPlacedOrderIcons-row {
  position: relative;
  bottom: 5px;
}

#lastPlacedOrderPrice-row {
  align-self: flex-start;
  color: white;
  font-size: 10px;
  margin-left: 7px;
}

#placeOrderPrice-row {
  align-self: center;
  color: white;
  font-size: 14px;
  line-height: 30px;
}

#lastPlacedOrderTypeUp-row {
  align-self: flex-start;
  color: white;
  font-size: 14px;
  margin-left: 10px;
  height: 0px;
  line-height: 0px;
}

#lastPlacedOrderTypeDown-row {
  align-self: flex-end;
  color: white;
  font-size: 14px;
  margin-right: 10px;
  height: 0px;
  line-height: 0px;
}

.lastPlacedOrderIcons-row > svg > g > path {
  opacity: 1 !important;
}

#placeOrderUp {
  color: #239e23;
  margin-bottom: 15px;
}

#placeOrderDown {
  color: #da3434;
  margin-top: 15px;
}

.orderIcons {
  font-size: 80px;
  height: 110px;
  width: 110px;
  border-radius: 10px;
}

.orderIcons > div > svg > g > path {
  opacity: 1 !important;
}

p.percentageLeft {
  float: left;
  width: 34%;
  text-align: left;
  margin-top: 8px;
  line-height: 0;}

p.percentageCenter {
  float: left;
  width: 33%;
  text-align: center;
  margin-top: 8px;
  line-height: 0;}

p.percentageRight {
  float: left;
  width: 33%;
  text-align: right;
  margin-top: 8px;
  line-height: 0;}

#pred-payout-area {
  text-align: center;
  padding: 18px;
  padding-top: 58px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
}

.flex-container-start {
  display: flex;
  justify-content: flex-start;
}

#place-Order-Down-row {
  color: #da3434;
  margin-left: 5px;
}

#place-Order-Up-row {
  color: #239e23;
}

.order-icons-row {
  margin-right: 15px;
  font-size: 50px;
  height: 50px;
  width: 80px;
  border-radius: 5px;
}

.order-icons-row > div > svg > g > path {
  opacity: 1 !important;
}

#pred-amount-expiry-area-row {
  max-width: 200px;
}

#pred-amount-select-row {
  width: 200px;
  margin-top: 10px;
}

#pred-expiry-select-row {
  width: 80px;
  margin-left: 15px;
  margin-top: 10px;
}

#pred-order-area-row {
  width: 310px;
  max-height: 100px;
  margin-top: 10px;
}

#pred-payout-area-row {
  text-align: center;
  width: 295px;
  max-height: 100px;
  font-size: 12px;
  margin-top: 10px;
  padding: 7px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
}