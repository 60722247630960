.board-card-grid {
  height: 34vh;
}
#board-card-news-grid {
  height: 35vh;
}
#board-card-news-grid-small {
  height: 30vh;
}
#board-card-user-com-grid {
  height: 35vh;
}
#board-card-user-com-grid-small {
  height: 30vh;
}
.board-card-grid-small {
  height: 29vh;
}
#board-doc-card-grid {
  height: 25vh;
}
#board-doc-card-grid-small {
  height: 18vh;
}
#board-doc-load-line {
  margin-right: 0;
  margin-left: auto;
  margin-top: 10px;
}
#board-contact-card-grid {
  height: 25vh;
}
#board-contact-card-grid-small {
  height: 18vh;
}
#board-company-calendar-sched {
  height: 30vh;
}
#board-company-calendar-sched-small {
  height: 25vh;
}
#board-cases-Grid {
  height: 31vh;
}
#board-cases-Grid-small {
  height: 25vh;
}
#board-cases-tasks-grid {
  height: 31vh;
}
#board-cases-tasks-grid-small {
  height: 25vh;
}
#board-contracts-card-grid {
  height: 30vh;
}
#board-contracts-card-grid {
  height: 25vh;
}
#board-card-sls-opp-mod-state-btn {
  margin-left: 10px;
  margin-top: 10px;
}
#board-card-cases-notes-grid {
  margin-bottom: 10px;
  height: 35vh;
}
#board-card-cases-notes-grid-small {
  margin-bottom: 10px;
  height: 30vh;
}
.vert-container {
  flex-direction: column;
  align-content: stretch;
}
#grid-case-board-card {
  flex-grow: 1;
  height: 300px;
}
