.center-area {
  display: flex;
  height: 36vh;
}

.center-child {
  width: 200px;
  height: 50vh;
  margin: auto;
}

#def-card-btn {
  margin-top: 20px;
  width: 200px;
  height: 31px;
}

#board-box-line-top {
  height: 45vh;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}

#board-box-line-main-one {
  height: 45vh;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}

#board-box-line-bottom {
  height: 40vh;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}

#board-box-line-main-two {
  height: 80vh;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}

#board-card-width {
  width: 350px;
  flex-basis: 300px;
  flex-grow: 1;
}

#board-card-width-flex {
  flex-basis: 300px;
  flex-grow: 1;
}

#board-card-width-2 {
  width: 350px;
  flex-basis: 300px;
  flex-grow: 2;
}

#board-card-width-1 {
  width: 350px;
  flex-basis: 350px;
  flex-grow: 0.5;
}

#board-card-width-3 {
  width: 350px;
  flex-basis: 350px;
  flex-grow: 0.1;
}

.flex-container-board {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
}

.line-dialog-aera {
  display: flex;
}

#board-layout-label {
  margin-right: 5px;
  margin-left: 10px;
  margin-top: 10px;
}

#board-layout-btn {
  margin-left: 15px;
}

#board-layout-line2 {
  margin-top: 10px;
}

#board-card-width-11 {
  width: 350px;
  flex-basis: 300px;
  flex-grow: 1;
}
#board-card-width-12 {
  min-width: 35vw;
  flex-basis: 35vw;
  flex-grow: 0.5;
}
#board-card-width-13 {
  min-width: 25vw;
  flex-basis: 35vw;
  flex-grow: 3;
}
#board-def-btn-1 {
  margin-right: 10px;
}
#board-def-card-selbox {
  margin-top: 10px;
}
#board-def-number-box {
  margin-bottom: 10px;
  margin-top: 10px;
}
