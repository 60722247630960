:root {
    --wheel-font: 'Lato', 'Quicksand', sans-serif;
    --wheel-size: 300px;
    --wheel-slice-spacing: 50px;
    --wheel-border-size: 5px;
    --wheel-color: black;
    --neutral-color: white;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 4s;
    --reset-duration: 0.25s;
  }

  .win {
    background-color: #239e23;
    height: 20px;
    line-height: 20px;
  }

  .lose {
    background-color: #da3434;
    height: 20px;
    line-height: 20px;
  }
  
  .wheel-container {
    display: block;
    position: relative;
    box-sizing: content-box;
    width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    padding: 3px;
    margin: auto;
    background-color: var(--neutral-color);
    border: solid var(--wheel-color) 3px;
    border-radius: 50%;
    user-select: none;
  }
  
  .wheel-container::before,
  .wheel-container::after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 499;
    border: solid transparent 20px;
    border-left-width: 0;
  }
  
  .wheel-container::before {
    right: 0px;
    border-right-color: var(--wheel-color);
  }
  
  .wheel-container::after {
    right: -5px;
    border-right-color: var(--neutral-color);
  }
  
  .wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: var(--wheel-size);
    height: var(--wheel-size);
    overflow: hidden;
    border-radius: 50%;
    border: solid var(--wheel-color) var(--wheel-border-size);
    background-color: var(--wheel-color);
    transition: transform var(--reset-duration);
    transform: rotate(0deg);
    cursor: pointer;
  }
  
  .wheel.stopped {
    transition: transform 0.5s;
    transform: rotate(calc(-360deg * var(--selected-item) / var(--nb-item, 1)));
  }

  .wheel.spinning {
    transition: transform var(--spinning-duration);
    transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
  }

  .wheel::before {
    display: block;
    position: absolute;
    content: '';
    background-color: var(--neutral-color);
    width: 120px;
    height: 120px;
    z-index: 501;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  .wheel::after {
    display: block;
    position: absolute;
    content: '';
    background-color: black;
    width: 180px;
    height: 180px;
    z-index: 500;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  
  .wheel-item {
    display: block;
    position: absolute;
    box-sizing: border-box;
  
    top: 50%;
    left: 50%;
    width: 54%;
    transform-origin: center left;
    transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));
  
    color: var(--neutral-color);
    text-align: right;
    padding: 0 25px 0 50px;
    font-family: var(--wheel-font);
  }
  
  .wheel-item:before {
    content: ' ';
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: 498;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0px;
    opacity: 0.25;
    --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
    --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
    border: solid transparent calc(var(--slice-width) / 2);
    border-left: solid transparent 0;
    border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
  }
  